.customer_reviews {
  position: relative;
  padding: 60px 0px;
  background: #fafafa;

  &-header {
    color: #011842;
    font-size: 32px;
    line-height: 51px;
    font-weight: 600;
    font-family: "Open Sans", serif;
    text-align: center;
  }

  h1 {
    color: #004061;
    font-size: 36px;
    margin: 0;
    letter-spacing: 0.01em;
  }

  &-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-wrapper {
      width: 80%;
    }
  }
}

.reviews_grid {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  position: relative;
  min-height: 242px;

  &:before {
    content: "\f10e";
    position: absolute;
    right: 25px;
    font-size: 50px;
    font-family: "FontAwesome";
    color: #004061;
    opacity: 0.06;
    top: 20px;
  }
}

.rev_content p {
  margin: 0;
  color: #666666;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 24px;
}

.rev_star_rating i {
  color: #efce4a;
  margin-right: 2px;
  font-size: 18px;
}

.reviews_slider {
  position: relative;
  margin-top: 40px;
  margin-left: -15px;
  margin-right: -15px;

  .slick-next {
    right: 15px;
    position: absolute;
    top: -40px;
    width: 54px;
    height: 60px;
    background: #004061;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 64, 97, 0.2);

    &:before {
      opacity: 1;
      font-family: "FontAwesome";
    }
  }

  .slick-prev {
    left: auto;
    right: 85px;
    top: -40px;
    position: absolute;
    z-index: 9;
    width: 54px;
    height: 60px;
    background: #004061;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 64, 97, 0.2);

    &:before {
      opacity: 1;
      font-family: "FontAwesome";
    }
  }
}

.rev_profile {
  margin-bottom: 25px;
  position: relative;

  h4 {
    color: #004061;
    letter-spacing: 0.01em;
    font-size: 21px;
    margin-bottom: 5px;
  }

  img {
    width: 52px;
    height: 52px;
    margin-right: 15px;
    border-radius: 50%;
  }
}
