.hero_banner_old {
  background: url(../../Assets/Images/hero.jpg) no-repeat bottom;
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  min-height: calc(100vh + 76px);
  margin-top: -76px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-content {
    max-width: 1074px;
    margin: 0 0 0 140px;
    padding: 0 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: 780px) {
      margin: 0 30px;
    }

    h1 {
      font-weight: 700;
      color: #fff;
      font-size: 60px;
      line-height: 80px;
      margin-bottom: 30px;
      width: 100%;
      max-width: 600px;
      text-align: left;
      font-family: "Open Sans", sans-serif;

      @media only screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 18px;
      }

      @media only screen and (max-width: 481px) {
        font-size: 24px;
        line-height: normal;
        margin-bottom: 10px;
      }

      @media only screen and (max-width: 320px) {
        font-size: 20px;
        line-height: normal;
        margin-bottom: 7px;
      }
    }

    p {
      color: #fff;
      font-size: 22px;
      line-height: 40px;
      margin-bottom: 30px;
      width: 100%;
      max-width: 460px;
      text-align: left;
      font-weight: 400;
      font-family: "Open Sans", sans-serif;

      @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }

      @media only screen and (max-width: 481px) {
        font-size: 14px;
        line-height: normal;
      }

      @media only screen and (max-width: 320px) {
        font-size: 12px;
        line-height: normal;
      }
    }

    .custom_btn {
      font-size: 20px;
      padding: 16px 60px;

      @media only screen and (max-width: 768px) {
        font-size: 16px;
        padding: 8px 30px;
      }

      @media only screen and (max-width: 320px) {
        font-size: 12px;
        padding: 4px 16px;
      }
    }
  }
}
