.process_free {
  padding-top: 80px;
  padding-bottom: 120px;
  background-color: #e8eaef;

  &-wrapper {
    padding-right: 102px !important;

    @media only screen and (max-width: 768px) {
      padding-right: 0 !important;
    }

    &-title {
      color: #011842;
      font-size: 32px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 20px;

      @media only screen and (max-width: 320px) {
        font-size: 26px;
        line-height: normal;
      }
    }

    &-description {
      color: #545454;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 70px;
      font-family: "Open Sans", sans-serif;

      @media only screen and (max-width: 320px) {
        font-size: 16px;
        line-height: normal;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .start-btn {
      padding-left: 58px;
      padding-right: 58px;
      border-width: 0;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }
}
