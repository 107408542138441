.services {
  padding: 60px 0px;
  &-sub-title {
    text-align: center;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #4b6eae;
    margin-bottom: 20px;

    @media only screen and (max-width: 481px) {
      font-size: 18px;
    }
  }

  &-title {
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;

    @media only screen and (max-width: 481px) {
      font-size: 22px;
    }
  }

  &-desc {
    color: #545454;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.17px;
    max-width: 900px;
    margin: 0px auto 60px auto;

    @media only screen and (max-width: 481px) {
      font-size: 16px;
    }
  }

  &_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 481px) {
      grid-template-columns: 1fr;
    }

    &-item {
      transform-style: preserve-3d;
      transition: all 1s linear;
      position: relative;

      &:hover {
        transform: rotateY(180deg);
      }

      &-front {
        position: absolute;
        backface-visibility: hidden;
        width: 100%;

        &-card {
          border-radius: 20px;
          border: 1px solid rgba(197, 197, 197, 0.25);
          background: #fff;
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
          padding: 90px 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 240px;

          &-img {
          }

          &-desc {
            margin-top: 40px;
            color: #222;
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            font-family: "Open Sans";
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      &-back {
        display: block;
        transform: rotateY(180deg);
        backface-visibility: hidden;

        &-card {
          min-height: 382px;
          border-radius: 20px;
          border: 1px solid rgba(197, 197, 197, 0.25);
          background: #fff;
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &-title {
            color: #222;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 20px;
            font-family: "Open Sans";
          }

          &-desc {
            color: #545454;
            font-size: 16px;
            list-style: disc;
            li {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  &-footer {
    text-align: center;
    margin-top: 60px;
    &-btn {
      padding: 15px 50px !important;
    }
  }
}
