.flag-dropdown {
  border: 1px solid #EAEAEA !important;

  .selected-flag {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}

.react-tel-input {
  .special-label {
    top: 6px;
    font-size: 14px;
    left: 47px;
    color: #a3a3ad;
    display: block;
    background-color: transparent
  }
}