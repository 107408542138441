/* New footer css start here  */

.footer_old {
  position: relative;
  padding: 88px 0px;
  background-color: #011842;

  @media only screen and (max-width: 600px) {
    padding: 88px 60px;
  }
}

.footer_widgets {
  @media only screen and (max-width: 600px) {
    margin-bottom: 50px !important;
  }

  h3 {
    text-transform: uppercase;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 18px;
    font-family: "Open Sans", sans-serif;
  }

  a {
    text-decoration: none;
  }

  &-section {
    @media only screen and (max-width: 600px) {
    }
  }
}
ul.featured_link li a {
  color: #d3d3d3;
  line-height: 33px;
}

ul.featured_link li .request-demo {
  color: #d3d3d3;
  line-height: 33px;
  cursor: pointer;
}

.copyright_txt p {
  letter-spacing: 3px;
  margin: 0;
  color: #fff;
  text-align: right;
}
.copyright_txt {
  padding-top: 36px;
  margin-top: 20px;
}
.social_media_footer li {
  margin-right: 30px;
}
.social_media_footer i {
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  border: 1px solid rgba(182, 197, 234, 0.5);
}
.social_media_footer i.fa.fa-twitter {
  color: #00acee;
}
.social_media_footer i.fa.fa-facebook {
  color: #3b5998;
}
.social_media_footer i.fa.fa-linkedin {
  color: #0072b1;
}
.footer_logo img {
  max-width: 195px;
  margin-bottom: 38px;
}
.footer_contact_info li a {
  color: #fff;
}

.footer_contact_info li .request-demo {
  color: #fff;
  text-decoration: underline;
}

.footer_contact_info li img {
  max-width: 18px;
  margin-right: 12px;
}
.footer_contact_info li {
  margin-bottom: 15px;
}

footer.footer.isLoggedIn,
footer.footer {
  display: none;
}
footer.footer.showFooter {
  display: block;
}
footer.footer.isHome {
  display: block;
}
.footer_content {
  color: #fff;
  font-size: 12px;
  padding-top: 56px;
  margin: 0;
}

.footer_main {
  width: 100%;
  position: relative;
  padding: 20px;
  z-index: 102;
  background-color: #114061;
  color: #fff;
  font-size: 12px;
  max-height: 128px;
  overflow: auto;

  &-only {
    margin-bottom: 0;
  }

  &-legal {
    margin-bottom: 0;
  }
}
