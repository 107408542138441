.card_parent {
  margin-top: 25px;

  .card {
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.card_title {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  background: #f7f7f7;
}

input.input__element:read-only {
  background: #fafafa;
  border: none;
  color: #9e9e9e;
  font-size: 16px;
  font-weight: normal;
}

.change_password_form {
  position: relative;
  padding: 20px;
}

.my_account_vs {
  height: 100%;
  overflow: auto;
}

.my_account_vs .card_title.card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0px 1px 20px 0px rgba(69, 90, 100, 0.08);
  box-shadow: 0px 1px 20px 0px rgba(69, 90, 100, 0.08);
  color: #004061;
  font-size: 18px;
  font-weight: 600;
}

.my_account_vs .card_title.card-header:before {
  content: "";
  background-color: #63b8a7;
  position: absolute;
  left: 0;
  top: 50%;
  width: 4px;
  height: 30px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.did-floating-input:disabled {
  background: rgba(128, 128, 128, 0.2);
  cursor: not-allowed;
  color: #004061;
}

.my_account {
  padding: 38px 55px;
  font-family: "Open Sans", sans-serif;

  &-title {
    font-size: 28px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 26px;
    font-family: "Open Sans", sans-serif;
  }

  &-tab {
    border-bottom: 2px solid #e7e7e7;

    .nav-item {
      margin-bottom: -2px;
      .nav-link {
        color: #7a7a7a;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 28px;
        margin-bottom: 20px;
        border-radius: 30px;
      }

      .nav-link.active {
        // border-bottom: 2px solid #004061;
        background-color: #4b6eae;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.change_profile_form {
  padding: 22px 0 41px 0;
  border-bottom: 1px solid #e7e7e7;

  &-title {
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &-save-btn {
    padding: 8px 23px;
    background-color: #4b6eae;
    border-radius: 20px;
    border: none;
    margin-top: 30px;

    span {
      color: #fff;
      font-size: 12px;
      font-family: "Open Sans", sans-serif;
    }
  }

  &-avatar {
    position: relative;
    &-img {
      width: 172px;
      height: 172px;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #fff;
      cursor: pointer;
      border: 1px solid gray;
      background-position: center;
      background-size: cover;
    }

    &-name {
      width: 72px;
      height: 72px;
      background: #63b8a7;
      border-radius: 100px;
      justify-content: center;
      align-items: center;
      display: flex;
      color: #fff;
      cursor: pointer;
    }

    &-icon {
      position: absolute;
      bottom: 0;
      left: 140px;
      cursor: pointer;
    }
  }
}

.change_password_form {
  padding: 22px 0 41px 0;
  border-bottom: 1px solid #e7e7e7;

  &-title {
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &-save-btn {
    padding: 8px 23px;
    background-color: #4b6eae;
    border-radius: 20px;
    border: none;
    margin-top: 30px;

    span {
      color: #fff;
      font-size: 12px;
      font-family: "Open Sans", sans-serif;
    }
  }
}
