.family {
  padding: 60px 0px;
  &-sub-title {
    text-align: center;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #4b6eae;
    margin-bottom: 20px;

    @media only screen and (max-width: 481px) {
      font-size: 18px;
    }
  }

  &-title {
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 90px;

    @media only screen and (max-width: 481px) {
      font-size: 22px;
    }
  }

  &_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px;

    @media only screen and (max-width: 481px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
    }

    &-card {
      border-radius: 20px;
      border: 1px solid rgba(197, 197, 197, 0.25);
      background: #fff;
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
      padding: 90px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-img {
      }

      &-desc {
        color: #545454;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }

      &-title {
        text-align: center;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .active-card {
      transform: scale(1.15);
      background-color: #f2f7ff;

      @media only screen and (max-width: 481px) {
        transform: none;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .family_container-card-title {
        color: #011842;
      }

      .family_container-card-btn {
        border-radius: 30px;
        padding: 10px 30px;
        // border: 1px solid #4b6eae;

        // color: #4b6eae;
        // font-family: "Open Sans";
        // font-size: 22px;
        // font-style: normal;
        // font-weight: 600;
        // line-height: normal;
        // background-color: #fff;
        // text-decoration: none;
      }
    }
  }
}
