.call_to_Action {
  position: relative;
  padding: 70px 0px;

  &-header {
    color: #fff;
    font-size: 32px;
    line-height: 51px;
    font-weight: 600;
    font-family: "Open Sans", serif;
    margin-bottom: 15px;
  }

  &-desc {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    font-family: "Open Sans", serif;
    margin-bottom: 23px;
  }

  .container {
    position: relative;
  }

  h1 {
    color: #fff;
    font-size: 46px;
    letter-spacing: 0.01em;
    margin-bottom: 15px;
  }

  p {
    color: #fff;
    font-size: 20px;
    letter-spacing: 0.01em;
  }

  &:before {
    content: "";
    position: absolute;
    background: #011842;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.85;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 38px;

    @media only screen and (max-width: 600px) {
      gap: 10px;
    }

    &-btn-request {
      border-radius: 100px !important;
    }
  }
}

.callaction_btn_group {
  margin-top: 40px;
  margin-bottom: 0;

  li {
    padding: 0px 15px;
  }
}
