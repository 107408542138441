.upload {
  .modal-dialog {
    .modal-content {
      border-radius: 30px;
    }
  }

  &_modal {
    padding: 50px 20px;

    &-wrapper {
      font-family: "Open Sans", sans-serif;

      &-close {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      &-title {
        color: #000;
        text-align: center;
        font-size: 22px;
        line-height: normal;
        margin-bottom: 14px;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;

        @media only screen and (max-width: 480px) {
          font-size: 26px;
          line-height: 32px;
        }
      }

      &-box {
        border: 1px dashed #4b6eae;
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &-icon {
          margin-bottom: 13px;
        }

        &-label {
          font-size: 16px;
          color: #000;
          font-family: "Open Sans", sans-serif;
          text-align: center;
          margin-bottom: 20px;

          &-link {
            color: #4b6eae;
            font-size: "Open Sans", sans-serif;
          }
        }

        &-tip {
          color: #797979;
          text-align: center;
          font-size: 12px;
          font-family: "Open Sans", sans-serif;
        }

        &-file {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: 0;
        }

        &-container {
          margin-bottom: 20px;
          &-item {
            padding: 10px 20px;
            border-radius: 20px;
            background: #cccccc;
          }
        }
      }

      &-desc {
        color: #545454;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 60px;
        font-family: "Open Sans", sans-serif;

        @media only screen and (max-width: 480px) {
          font-size: 14px;
          line-height: 26px;
        }
      }

      &-img {
        margin: 20px auto;
        display: flex;
        justify-content: center;

        &-avatar {
          width: 100px;
          height: 100px;
          border-radius: 100px;
          overflow: hidden;
          background-position: center;
          background-size: cover;
        }

        &-name {
          width: 100px;
          height: 100px;
          background-color: aqua;
          border-radius: 100px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }

      &-footer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;

        .btn-cancel {
          padding: 8px 28px;
          border-radius: 30px;
          border: 1px solid #4b6eae;
          box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);

          .button__text {
            color: #4b6eae;
            font-size: 12px;
          }
        }
        .btn-upload {
          padding: 8px 28px;
          border-radius: 30px;
          border: 1px solid #4b6eae;
          box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
          background-color: #4b6eae;

          .button__text {
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
}
