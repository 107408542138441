.welcome {
  .modal-dialog {
    .modal-content {
      border-radius: 30px;
    }
  }

  &_modal {
    padding: 50px 20px;

    &-wrapper {
      font-family: "Open Sans", sans-serif;

      &-close {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      &-title {
        color: #22427d;
        text-align: center;
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 14px;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;

        @media only screen and (max-width: 480px) {
          font-size: 26px;
          line-height: 32px;
        }
      }

      &-desc {
        color: #545454;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        margin-bottom: 60px;
        font-family: "Open Sans", sans-serif;

        @media only screen and (max-width: 480px) {
          font-size: 14px;
          line-height: 26px;
        }
      }

      &-img {
        margin: 20px auto;
        display: flex;
        justify-content: center;
      }

      &-footer {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        &-btn {
          max-width: 320px;
        }
      }
    }
  }
}
