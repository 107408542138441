.processe_section {
  padding: 86px 20px 127px;

  &-header {
    margin-bottom: 26px;

    &-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 51px;
      color: #011842;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 24px;

      @media only screen and (max-width: 768px) {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    &-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #545454;
      margin: 0 auto 50px auto;
      font-family: "Open Sans", sans-serif;
      width: 100%;
      max-width: 820px;

      @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: normal;
      }
    }
  }
}

.horizontal_tab_data {
  margin-top: 60px;
}

.processe_tile {
  background: #ffffff;
  border-radius: 20px;
  padding: 42px 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  border: 1px solid rgba(197, 197, 197, 0.25);
  margin: 32px 20px;
  box-shadow: 2px 2px 10px #0000000d;

  .processe_parent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .processe_label p {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #222;
      margin-top: 16px;
      margin-bottom: 16px;
      font-family: "Open Sans", serif;
    }

    .processe_description p {
      color: #545454;
      font-size: 16px;
      font-family: "Open Sans", serif;
      line-height: 26px;
      font-weight: 400;
      width: 100%;
      max-width: 234px;
    }
  }

  .processe_image {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 7px solid #e9eef9;
    height: 120px;
    width: 120px;

    img {
      max-width: 100px;
      max-height: 100px;
    }
  }
}
.horizontal_tab {
  justify-content: center;
  border: none !important;
}

.horizontal_tab li.nav-item button {
  border: 1px solid #004061;
  border-radius: 4px;
  margin: 0 15px;
  box-shadow: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #004061;
  font-family: "Open Sans", sans-serif;
  min-width: 250px;
  padding: 12px;
}

.horizontal_tab li.nav-item button.active,
.horizontal_tab li.nav-item button:hover,
.horizontal_tab li.nav-item button:active {
  background-color: #004061;
  color: #ffffff;
}
