.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  padding-top: 68px;
  z-index: 999;
}

.side_bar {
  padding: 8px;
  overflow-y: scroll;
  display: block;
  margin-right: -294px;
  width: 294px;
  background-color: #fff;
  box-shadow: -3px 8px 9px #888888;
  -webkit-transition: opacity 0.3s linear, margin-right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, margin-right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, margin-right 0.3s ease-out;
  transition: opacity 0.3s linear, margin-right 0.3s ease-out;
}

.side_bar.show {
  margin-right: 0px;
  -webkit-transition: opacity 0.3s linear, margin-right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, margin-right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, margin-right 0.3s ease-out;
  transition: opacity 0.3s linear, margin-right 0.3s ease-out;
}
