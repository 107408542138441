.features {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 129px;
  margin-top: 74px;

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  &-title {
    margin-bottom: 74px;
    color: #011842;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    line-height: 51px;
    font-weight: 600;
  }
}

.featured {
  display: flex;
  justify-content: center;

  &_card {
    max-width: 900px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 40px 50px;
    margin-bottom: 50px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 20px;
      margin-bottom: 30px;
    }

    img.card-img {
      max-width: 100px;
      margin: 0 auto 20px;
    }

    &.card {
      background: #ffffff;
      border: 1px solid #e7e7e7;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding: 55px 30px 48px 30px;
      text-align: center;

      .card-body {
        padding: 0;
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      margin-right: 30px;

      @media only screen and (max-width: 600px) {
        margin-right: 0px;
      }

      &-container {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        border: 7px solid #dde9f0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        &-img {
          width: 54px;
        }
      }
    }

    &-body {
      &-title {
        color: #222222;
        font-size: 26px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 15px;
        font-family: "Open Sans", serif;
        text-align: left;

        @media only screen and (max-width: 600px) {
          text-align: center;
        }
      }

      &-desc {
        color: #545454;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0.01em;
        margin-bottom: 0;
        font-family: "Open Sans", serif;
        text-align: left;
        @media only screen and (max-width: 600px) {
          text-align: center;
        }
      }
    }
  }
}
