/* New footer css start here  */

.foot {
  position: relative;
  background-color: #fff;

  @media only screen and (max-width: 600px) {
    padding: 88px 60px;
  }

  &_widgets {
    @media only screen and (max-width: 600px) {
      margin-bottom: 50px !important;
    }

    &-title {
      color: #222222;
      font-size: 20px;
      letter-spacing: 1px;
      font-weight: 700;
      margin-bottom: 18px;
      font-family: "Open Sans", sans-serif;
    }

    &-list {
      padding: 0;
      &-link {
        text-decoration: none;
        color: #222;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 33px;

        cursor: pointer;
      }

      &-social {
        display: flex;
        padding: 0px;

        li {
          margin-right: 20px;
          a {
            color: #d3d3d3;
            line-height: 33px;
          }

          i {
            height: 40px;
            width: 40px;
            background: #fff;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
            border: 1px solid rgba(182, 197, 234, 0.5);
          }

          i.fa.fa-twitter {
            color: #00acee;
          }

          i.fa.fa-facebook {
            color: #3b5998;
          }

          i.fa.fa-linkedin {
            color: #0072b1;
          }
        }
      }
    }

    &-section {
      @media only screen and (max-width: 600px) {
      }
    }
  }

  &_logo {
    a {
      text-decoration: none;
    }
    &-title {
      color: #22427d;
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 23px;
    }

    &-desc {
      color: #222;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 40px;
    }

    &-copyright {
      font-family: "Open Sans", sans-serif;
      margin-top: 90px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
    }
  }

  &_con {
    background-color: #f2f7ff;
    padding: 100px 0px 80px 0px;
  }

  &_pol {
    background-color: #fff;
    padding: 40px 0px;

    &-desc {
      color: #505050;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 0px;
    }
  }
}
