.plan {
  &-title {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    line-height: 38px;
    color: #000;
    font-weight: 700;
    margin-bottom: 69px;
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &-card {
      border-radius: 10px;
      border: 1px solid #f3f3f3;
      background-color: #fff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 20px 40px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 500px;

      &-header {
        &-title {
          display: flex;
          align-items: center;
          margin-bottom: 40px;

          &-img {
            margin-right: 13px;
          }

          &-text {
            font-size: 22px;
            font-weight: 700;
            line-height: normal;
          }
        }

        &-price {
          &-cha {
            color: #22427d;
            font-size: 22px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 12px;
          }

          &-sub {
            color: #545454;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
          }

          &-tip {
            color: #22427d;
            font-size: 12px;
            font-weight: 700;
            font-family: "Open Sans", sans-serif;
            margin-bottom: 5px;
          }

          &-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 19px;

            &-cha {
              color: #22427d;
              font-size: 20px;
              font-weight: 700;
              line-height: normal;
            }

            &-sub {
              color: #ababab;
              font-size: 14px;
              font-weight: 600;
              line-height: normal;
            }
          }
        }

        .discounted {
          margin-bottom: 20px;

          .plan-container-card-header-price-tip {
            color: #545454;
          }

          .plan-container-card-header-price-wrap-cha {
            // text-decoration: line-through;
            color: #545454;
            font-size: 14px;
          }

          .plan-container-card-header-price-wrap-sub {
            text-decoration: line-through;
          }
        }

        .new_calculate {
          // .plan-container-card-header-price-cha {
          //   font-size: 22px;
          // }

          .plan-container-card-header-price-sub {
            font-size: 14px;
          }

          .plan-container-card-header-price-wrap-sub {
            text-decoration: line-through;
          }
        }

        &-desc {
          margin: 43px 0 20px 0;
          font-size: 14px;
          color: #545454;
          line-height: 20px;
          font-weight: 400;
        }

        &-slider {
          display: flex;
          align-items: center;
          margin: 10px 0px;

          &-line {
            flex: 1;
            border-bottom: 1px solid #f8f8f8;
          }

          &-label {
            color: #ababab;
            font-size: 10px;
            font-weight: 700;
            font-family: "Open Sans", sans-serif;
            margin: 0px 10px;
          }
        }

        &-lifetime {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #f8f8f8;
          border-radius: 5px;
          padding: 8px;

          &-wrapper {
            display: flex;
            align-items: center;

            &-img {
              margin-right: 6px;
            }

            &-content {
              &-title {
                color: #22427d;
                font-size: 14px;
                font-weight: 700;
                font-family: "Open Sans", sans-serif;
                margin-bottom: 6px;
              }

              &-desc {
                color: #545454;
                font-size: 12px;
                font-weight: 400;
                font-family: "Open Sans", sans-serif;
                margin-bottom: 0px;
              }
            }
          }

          &-value {
            color: #22427d;
            font-size: 16px;
            font-family: "Open Sans", sans-serif;
            font-weight: 700;
          }
        }
      }

      &-footer {
        .plan_button_dropdown button.dropdown-toggle.btn.btn-primary {
          background: transparent;
          border: 1px solid #4b6eae;
          border-radius: 18.5px;
          min-width: 155px;
          color: #4b6eae;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          transition: all 0.5s;
          outline: none !important;
          box-shadow: none !important;
          padding: 10px;
          width: 100%;
        }

        .plan_button_dropdown button.dropdown-toggle.btn.btn-primary:hover,
        .plan_button_dropdown button.dropdown-toggle.btn.btn-primary:focus,
        .plan_button_dropdown button.dropdown-toggle.btn.btn-primary:active {
          background: #4b6eae;
          color: #ffffff;
          outline: none !important;
          box-shadow: none !important;
        }

        .plan_button_dropdown .dropdown-menu {
          box-shadow: 0 0 35px 0 rgb(0 0 0 / 25%);
          border: none;
          margin-top: 10px;
        }
      }
    }
  }
}
