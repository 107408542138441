* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
}

body {
  padding: 0 !important;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "EB Garamond", serif;
}

iframe {
  border: none;
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none;
  color: unset;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 1074px;
}

.img-responsive {
  max-width: 100%;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.main_container {
  .App {
    width: calc(100% - 256px);
    margin-left: 256px;
    padding-top: 61px;
  }

  .loggedin_app {
    width: 100%;
    margin: 0;
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #22427d;
}

/* New home page css start here  */

.list-styled-none {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.btn.custom_btn {
  min-width: 220px;
  letter-spacing: 0.01em;
}

.btn.btn_border {
  min-width: 220px;
  height: 52px;
  letter-spacing: 0.01em;
  text-align: center;
  box-shadow: none;
  outline: none;
}

.banner_button {
  margin-top: 35px;
}

.StripeElement {
  display: block;
  max-width: 500px;
  padding: 18px 24px;
  font-size: 7em;
  border: 1px solid #eaeaea;
  border-radius: 30px;
  position: relative;

  .__PrivateStripeElement {
    height: 22px;
  }
}

.StripeElement--invalid {
  border: 1px solid red;
}

input.StripeElement::placeholder {
  color: #c0c0c0;
}

input.StripeElement:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.card-error {
  color: red;
}

label.didnot-floating-label {
  text-align: left;
  display: block;
  font-size: 16px;
}

.StripeElement {
  &.promo-wrapper {
    line-height: 0;
    font-size: 0;
    padding-top: 0;
    padding-bottom: 0;

    .promo-input {
      border: none;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 16px;
      height: 58px;
      outline: none;
    }
  }
}
